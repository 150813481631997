import { Card, Image, Typography, CircularProgress, Button, Actions, Modal } from '../components/library'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Grid } from '@material-ui/core'
import { DateTime } from 'luxon'
import { navigate } from 'gatsby'
import React, { useContext, useState } from 'react'
import { ImpactContext } from '../components/Layout'
import { Entity, SubmitButtonComponent } from '@parallelpublicworks/entitysync'
import { InputField } from '../components/library/entitysync'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import setBonesToBeAdded from '../utils/setBonesToBeAdded'

const useStyles = makeStyles(theme => {
  return {
    card: {
      padding: theme.spacing(2, 3)
    },
    spirometryResults: {
      padding: 0
    },
    downloadLinks: {
      width: '100%'
    },
    mirAppLogo: {
      width: '100%',
      height: '100%',
      paddingRight: 'auto'
    },
    spirometryFvc: {
      width: '100%',
      height: '181px',
      marginBottom: 'auto'
    },
    arrow: {
      width: '200px',
      height: '181px'
    },
    outerSpirobankContainer: {
      flexFlow: 'row',
      justifyContent: 'space-between'
    }
  }
})

const SubmitButton = SubmitButtonComponent(props => {
  return <Button onClick={props.onClick} {...props} />
})

function UnpackSpirometer() {
  const classes = useStyles()
  return (
    <>
      <Card shadowOff className={classes.card} bg="purple-light">
        <Typography shade="dark">1. Unpack the spirometer box and insert the batteries</Typography>
      </Card>
      <Card shadowOff>
        <Image src="/assemble-spirometer.svg" width="80%" />
      </Card>
    </>
  )
}

function GoToSpirometerApp() {
  const classes = useStyles()
  return (
    <>
      <Card shadowOff className={classes.card} bg="purple-light">
        <Typography shade="dark">
          2. Go to the MIR Spirobank App. Complete the patient form, connect your spirometer and select Spirometry FVC.
        </Typography>
      </Card>

      <Grid container className={classes.outerSpirobankContainer}>
        <Grid item container style={{ width: '200px' }} spacing={1}>
          <Grid xs={12} item>
            <img className={classes.mirAppLogo} src="/mir-spirobank-logo.png" />
          </Grid>
          <Grid direction="column" container item xs={12}>
            <Grid item md={6}>
              <a href="https://play.google.com/store/apps/details?id=com.spirometry.sara_android&hl=en_US&gl=US">
                <img className={classes.downloadLinks} src="/google-play-download-link.png" />
              </a>
            </Grid>
            <Grid item md={6}>
              <a href="https://play.google.com/store/apps/details?id=com.spirometry.sara_android&hl=en_US&gl=US">
                <img className={classes.downloadLinks} src="/apple-download-link.png" />
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ width: '200px' }} item xs={4}>
          <ArrowRightAltIcon className={classes.arrow} />
        </Grid>
        <Grid style={{ width: '200px' }} item>
          <img className={classes.spirometryFvc} src="/spirometry-fvc-button.png" />
        </Grid>
      </Grid>
    </>
  )
}

function EnterSpirometryData({ loading, spirometryFields }) {
  const classes = useStyles()
  return (
    <>
      {Object.keys(spirometryFields).map((objKey, idx) => {
        return (
          <InputField
            key={idx}
            type={spirometryFields[objKey].type}
            field={objKey}
            label={spirometryFields[objKey].label}
            disabled={loading}
            error={spirometryFields[objKey].error}
            helperText={spirometryFields[objKey].helperText}
          />
        )
      })}
    </>
  )
}

function SpirometryTestFormPage({ location }) {
  const forceBackTo = location.state ? location.state.forceBackTo : null
  const { userData, user, currentWeek, isPastWeek, previousWeek } = useContext(ImpactContext)
  const sequence = [UnpackSpirometer, GoToSpirometerApp, EnterSpirometryData]
  const hashNum = Number.parseInt(location.hash.replace('#', ''))
  const currentStep = hashNum && hashNum >= 1 && hashNum <= sequence.length ? hashNum : 1
  const CurrentStepComponent = sequence[currentStep - 1]
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [spirometryFields, setSpirometryFields] = useState({
    // field_fvc_actual: { type: 'number', error: false, helperText: '', label: 'FVC actual (L)' },
    field_fvc_predicted: { type: 'number', error: false, helperText: '', label: 'FVC % predicted' },
    // field_fev1_actual: { type: 'number', error: false, helperText: '', label: 'FEV1 actual (L)' },
    field_fev1_predicted: { type: 'number', error: false, helperText: '', label: 'FEV1 % predicted' },
    field_fev1_fvc: { type: 'number', error: false, helperText: '', label: 'FEV1/FVC %' }
  })
  const [userDataChangesCount, setUserDataChangesCount] = React.useState(0)
  const [spirometryDate, setSpirometryDate] = useState(null)
  const [backTo, setBackTo] = useState(null)
  const [boneID, setBoneID] = useState(null)
  const [goingAway, setGoingAway] = useState(false)

  React.useEffect(() => {
    if (forceBackTo) {
      setBackTo(forceBackTo)
    } else {
      setBackTo(isPastWeek && currentWeek.todo_left === 1 ? '/' : '/weekly-to-do')
    }
    setBoneID(DateTime.now().toISO())
  }, [])

  React.useEffect(() => {
    if (currentWeek !== null && spirometryDate === null) {
      const today = DateTime.now()
      setSpirometryDate(currentWeek.interval.contains(today) ? today : currentWeek.interval.e.plus({ minutes: -1 }))
    }
  }, [currentWeek])

  React.useEffect(() => {
    if (loading) {
      setUserDataChangesCount(userDataChangesCount + 1)
    }
  }, [userData, loading])

  React.useEffect(() => {
    if (userDataChangesCount >= 3 && !goingAway) {
      setGoingAway(true)
      const stored_spirometry_response = Object.keys(userData).find(obj_uuid => {
        const obj = userData[obj_uuid]
        const has_changed =
          obj.type === 'node--spirometer_result' &&
          typeof obj?.attributes?.field_test_date_iso === 'string' &&
          obj?.attributes?.field_test_date_iso === spirometryDate.toISO()
        return has_changed
      })
      if (stored_spirometry_response) {
        const bones_to_add = previousWeek && previousWeek.todo_done ? 2 : 1
        // console.warn('bones_to_add after spirometry', bones_to_add, 'because', previousWeek?.todo_done);
        setBonesToBeAdded(bones_to_add, boneID, 'Great job completing the spirometry test!', true)
        navigate('/spirometry-detail-view', { state: { response: userData[stored_spirometry_response], backTo } })
      }
    }
  }, [userDataChangesCount])

  function onSubmit(e, unsavedChanges, entityData) {
    setUserDataChangesCount(0)
    // let validpatternnumbers = /^([1-4])(?:\.\d{1,2})?$/
    let validpatternpercentage = /^([1-9]|[1-9][0-9]|1[01][0-9]|12[0-9]|130)(?:\.\d{1,2})?$/
    let attr = { ...unsavedChanges.attributes }
    let cacheFields = unsavedChanges.attributes
    if (entityData?.attributes?.changed) {
      attr = { ...entityData.attributes }
    } else {
      attr = attr
    }
    // let f_fvc_actual = validpatternnumbers.test(parseFloat(attr.field_fvc_actual))
    let f_fvc_predicted = validpatternpercentage.test(parseFloat(attr.field_fvc_predicted))
    // let f_fev1_actual = validpatternnumbers.test(parseFloat(attr.field_fev1_actual))
    let f_fev1_predicted = validpatternpercentage.test(parseFloat(attr.field_fev1_predicted))
    let f_fev1_fvc = validpatternpercentage.test(parseFloat(attr.field_fev1_fvc))
    if (
      spirometryDate === null ||
      backTo === null ||
      // f_fvc_actual != true ||
      f_fvc_predicted != true ||
      // f_fev1_actual != true ||
      f_fev1_predicted != true ||
      f_fev1_fvc != true
    ) {
      setSpirometryFields({
        field_fvc_predicted: {
          type: 'number',
          error: !f_fvc_predicted,
          helperText: 'This percentage should be a number from 1 to 130',
          label: 'FVC % predicted'
        },
        field_fev1_predicted: {
          type: 'number',
          error: !f_fev1_predicted,
          helperText: 'This percentage should be a number from 1 to 130',
          label: 'FEV1 % predicted'
        },
        field_fev1_fvc: {
          type: 'number',
          error: !f_fev1_fvc,
          helperText: 'This percentage should be a number from 0 to 100',
          label: 'FEV1/FVC %'
        }
      })
      setLoading(false)
      e.preventDefault()
    } else if (unsavedChanges && unsavedChanges.attributes) {
      unsavedChanges.attributes.field_fev1_fvc = cacheFields.field_fev1_fvc ? 
      cacheFields.field_fev1_fvc : attr.field_fev1_fvc
      unsavedChanges.attributes.field_fev1_predicted = cacheFields.field_fev1_predicted ? 
      cacheFields.field_fev1_predicted : attr.field_fev1_predicted
      unsavedChanges.attributes.field_fvc_predicted = cacheFields.field_fvc_predicted ? 
      cacheFields.field_fvc_predicted : attr.field_fvc_predicted
      unsavedChanges.attributes.title = 'Spirometry Result'
      unsavedChanges.attributes.field_test_date_iso = spirometryDate.toISO()
      if (isPastWeek) {
        unsavedChanges.attributes.field_test_week = `week${currentWeek.number - 1}`
      } else {
        unsavedChanges.attributes.field_test_week = previousWeek ? `week${previousWeek.number}` : `week${currentWeek.number - 1}`
      }
      setLoading(true)
    }
  }

  if (currentWeek === null) return <CircularProgress />

  if (currentWeek.spiro_done)
    return (
      <Modal id="spiro-form-modal" title="Spirometry Test" handleClose={e => navigate(-1)} open={true} size="xs">
        <Typography variant="body1" align="left">
          {`You already completed the spirometry test for this week.`}
        </Typography>
      </Modal>
    )

  return (
    <Entity type="node--spirometer_result" componentId="spirometry-entity">
      <Modal
        id="spirometry-test-form"
        title="Spirometry test"
        subtitle={`IMPACT Week ${currentWeek.number + 1}, ${currentWeek.label}`}
        open={true}
        size="sm"
        handleClose={e => navigate(-1)}
      >
        <CurrentStepComponent loading={loading} spirometryFields={spirometryFields} />
        <Actions
          currentStep={currentStep}
          totalSteps={sequence.length}
          dismissTo={`#${Math.min(currentStep - 1, sequence.length)}`}
          dismissLabel="Previous"
          dismissDisabled={currentStep === 1 || loading}
          submitTo={`#${Math.min(currentStep + 1, sequence.length)}`}
          submitLabel="Next"
          submitButton={
            <SubmitButton onSubmit={onSubmit} disabled={loading} variant="contained" color="primary">
              Submit
            </SubmitButton>
          }
          lastStep={currentStep == sequence.length}
        />
      </Modal>
    </Entity>
  )
}

export default SpirometryTestFormPage
